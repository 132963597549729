// jquery
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// alpinejs
import alpineJs from 'alpinejs';
window.Alpine = alpineJs;
Alpine.start();

// toastr
import toastr from "toastr";
window.toastr = toastr;

// SweetAlert2
import swal from "sweetalert2";
window.Swal = swal.mixin({
    customClass: {
        container: 'af-swal__container',
        header: 'af-swal__header',
        title: 'af-swal__title',
        confirmButton: 'af-swal__confirm-button',
        cancelButton: 'af-swal__cancel-button',
    },
});

// bootstrap
require('bootstrap');

// slick-carousel
require('slick-carousel');

// jquery-validation
require('jquery-validation');

// select2
require('select2')();
require('./select2-dropdownPosition');


// magicsuggest
require('magicsuggest');