jQuery(document).ready(function($){
    // navbar dropdown toggle level1 can clickable
    $('.navbar-nav > .dropdown > .dropdown-toggle').on('click', function () {
        if ($(window).width() > 992) {
            var currentHref = $(this).attr('href');
            if (currentHref && currentHref != '#') {
                window.location.href = currentHref;
            }
        }
    });

    // form validator defaults
    $.validator.setDefaults({
        ignore: [],
        errorElement: 'span',
        errorPlacement: function(error, element) {     
            error.addClass('invalid-feedback');

            // input group
            if (element.parent().is('.input-group')) {
                error.insertAfter(element.closest('.input-group'));
                return;
            }

            // select2
            if (element.next().is('.select2')) {
                error.insertAfter(element.next());
                return;
            }

            error.insertAfter(element);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).addClass('is-invalid');
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).removeClass('is-invalid');
        }
    });

    // init form validate
    if ($('form.js-form-validator').length > 0) {
        $('form.js-form-validator').each(function(){
            $(this).validate();
        });
    }

    // init form strict mode (only enabled submit button after form valid)
    if ($('form.js-form-strict').length > 0) {
        $('form.js-form-strict').each(function(){
            var $form = $(this);
            var $submitBtn = $(this).find('button[type="submit"]');
            $submitBtn.attr('disabled', true);

            $(this).find('input, select, textarea').on('input blur', function () {
                if ($($form).valid()) {                   
                    $submitBtn.prop('disabled', false);
                } else {
                    $submitBtn.prop('disabled', true);
                }
            });
        });
    }

    // select2
    var $select2 = $('.js-select2');
    $select2.each(function(){
        var $select = $(this).select2({
            width: '100%',
            allowClear: true,
            dropdownPosition: 'below',
        });
        
        /*
        * When you change the value the select via select2, it triggers
        * a 'change' event, but the jquery validation plugin
        * only re-validates on 'blur'
        */
        $select.on('change', function() {
          $(this).trigger('blur');
        });
    });

    // Enable tooltips everywhere
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    });

    // Refresh captcha
    var $refreshCaptchaBtn = $('.js-refresh-captcha');
    if ($refreshCaptchaBtn.length > 0) {
        var $captchaImage = $refreshCaptchaBtn.closest('.form-group').find('img.captcha-image');
        var captchaSrc = $captchaImage.data('captchaSrc');
        $refreshCaptchaBtn.on("click",function() {
            $(this).addClass('loading');
            var radomNumber = Math.floor(Math.random() * 10000);
            $captchaImage.attr('src', captchaSrc +'&n='+ radomNumber);
            return false;
        });
        $captchaImage.on('load',function(){
            $refreshCaptchaBtn.removeClass('loading');
        }); 
    }
});
