(function ($) {
    $(function() {
        // toggle input password
        $('.input-toggle-password .input-group-text').on('click', function(event) {
            event.stopPropagation();
            event.preventDefault();
            console.log('toggle password clicked');
            
            var $input = $(this).closest('.input-group').children('.form-control');
            if ($input.attr("type") == "text") {
                $input.attr('type', 'password');
                $(this).children('i').addClass("fa-eye-slash");
                $(this).children('i').removeClass( "fa-eye" );
            } else if($input.attr("type") == "password"){
                $input.attr('type', 'text');
                $(this).children('i').removeClass("fa-eye-slash");
                $(this).children('i').addClass("fa-eye");
            }
        });
        $('.input-toggle-password input[type="password"]').on('input blur', function(event) {
            console.log('toggle password input');
            if ($(this).val() !== '') {
                $(this).closest('.input-group').find('.input-group-text > i').fadeIn();
            } else {
                $(this).closest('.input-group').find('.input-group-text > i').fadeOut();
            }
        });
    });

    // WORKAROUND:
    // Show toggle icons for browser autocomplete behavior
    $(window).on('load', function() {
        var $passwords = $('.input-toggle-password input[type="password"]');
        if ($passwords.length > 0) {
            $passwords.each(function(){
                if ($(this).is(':-webkit-autofill')) {
                    $(this).closest('.input-group').find('.input-group-text > i').fadeIn();
                }
            });
        }
    });
})(jQuery);